/**
 * Matomo script
 */
export default function enableMatomo() {

  (() => {

    let app_matomo_host = 'matomo.biron75.xyz';
    let app_matomo_siteid = '2';
    let app_matomo_tagmanager_containerurl = 'https://matomo.biron75.xyz/js/container_oOKLb0IS.js';
    // let app_matomo_host = 'matomo.local';
    // let app_matomo_siteid = '1';
    // let app_matomo_tagmanager_containerurl = '';

    let _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      let u=`//${app_matomo_host}/`;
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', app_matomo_siteid]);
      let d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();

    // let _mtm = window._mtm = window._mtm || [];
    // _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    // let d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    // g.async=true; g.src=app_matomo_tagmanager_containerurl; s.parentNode.insertBefore(g,s);

  })()

}
