import { useTranslation } from "react-i18next";

function Preamble() {
	const { t } = useTranslation();

	return <div className="cv-section container mb-4 p-2" id="preamble">
				<h2 className="part-title mb-2 pb-2 border-bottom">{t('home_preamble.title')} 📖</h2>

				<p>
					{t('home_preamble.p1')}
					<br />{t('home_preamble.p2')}
				</p>
				<p>
					{t('home_preamble.p3')}
				</p>
				<p>
					{t('home_preamble.p4')}
				</p>
				<p>
					{t('home_preamble.p5')}
					<br />{t('home_preamble.p6')}
				</p>
			</div>;
}

export default Preamble;
