import './../assets/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
// import $ from 'jquery';
// import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Home from './Home';
import About from './About';
import enableMatomo from './_common/_matomo-script';

function App() {
	useEffect(() => {
    enableMatomo();
	}, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='*' element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
