import ScrollToTopButton from './ScrollToTopButton';
import { Trans, useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();

	return <footer className="py-5 text-center text-body-secondary bg-body-tertiary">
				<p>
					<ScrollToTopButton label={t('footer.backtotopbutton_text')} />
				</p>
				<p>
					<small>{t('footer.builtwithreact_text')} <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a>.</small>
					<br /><small>{t('footer.bootstrappagestyle_text.p1')} <a href="https://getbootstrap.com/docs/5.3/examples/" target="_blank" rel="noreferrer">{t('footer.bootstrappagestyle_text.p2')}</a></small>
					<br /><small>{t('footer.hostedbyovh_text')} <a href="https://www.ovhcloud.com/" target="_blank" rel="noreferrer">OVH</a></small>
				</p>
				<p className='mb-1'>
					<small style={{fontStyle: 'italic', opacity: '0.3'}}>
						"{t('footer.saying')}"
					</small>
				</p>
			</footer>
}

export default Footer;
