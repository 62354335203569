import React from 'react';
import '../../styles/Header.css';
import { useTranslation } from 'react-i18next';

function Header() {
	const lngs = {
		en: { shortName: 'en', nativeName: 'English' },
		fr: { shortName: 'fr', nativeName: 'Français' }
	};

	const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

	return <div className="container">
			<header className="lh-1 py-3">
				<div className="row flex-nowrap justify-content-between align-items-center">
					<div className="col-4 pt-1">
						<a className="link-secondary" href="mailto:davidgauthier75@gmail.com">
							{t('header.mailto_linktext')}
						</a>
					</div>
					<div className="col-4 text-center">
						<a className="blog-header-logo text-body-emphasis text-decoration-none d-none d-lg-block" href="/">davidgauthier.dev</a>
						<a className="blog-header-logo text-body-emphasis text-decoration-none d-lg-none" href="/">dg.dev</a>
					</div>
					<div className="col-4 d-flex justify-content-end align-items-center">
						{/* <a className="link-secondary" href="/about">About</a> */}
						{/* <a className="btn btn-sm btn-outline-secondary" href="#">Sign up</a> */}
						<div>
							{Object.keys(lngs).map((lng, index, array) => (
								<React.Fragment key={lngs[lng].shortName}>
									<button
										style={{
											fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
											textDecoration: i18n.resolvedLanguage === lng ? 'none' : 'underline'
										}}
										disabled={i18n.resolvedLanguage === lng}
										type="button"
										className="btn btn-link link-secondary m-0 p-0"
										onClick={() => changeLanguage(lng)}
										title={lngs[lng].nativeName}
									>
										{lngs[lng].shortName}
									</button>
									{index !== array.length - 1 && <span className="text-secondary"> | </span>}
								</React.Fragment>
							))}
						</div>
					</div>
				</div>
			</header>

			{/* <div className="nav-scroller py-1 mb-3 border-top border-bottom">
				<nav className="nav nav-underline justify-content-between">
					<a className="nav-item nav-link link-body-emphasis active" href="#">World</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">U.S.</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Technology</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Design</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Culture</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Business</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Politics</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Opinion</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Science</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Health</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Style</a>
					<a className="nav-item nav-link link-body-emphasis" href="#">Travel</a>
				</nav>
			</div> */}
		</div>
}

export default Header;
