import Education from "./Education";
import Experiences from "./Experiences";
import Languages from "./Languages";
import Preamble from "./Preamble";
import Skills from "./Skills";
import Various from "./Various";
import Div1 from "./Div1";
import Div2 from "./Div2";
import Div3 from "./Div3";
import MajorSection from "./MajorSection";
import RowCards from "./RowCards";
import Sidebar from "../_common/Sidebar";

function MainContainer() {
	return <main className="container">
			{/* <MajorSection /> */}
			{/* <RowCards /> */}

			<div className="row g-3">
				<div className="col-md-8" data-bs-spy="scroll" data-bs-target="#anchors-list" data-bs-offset="0" data-bs-smooth-scroll="true" tabIndex="0">
					{/* <h3 className="pb-4 mb-4 fst-italic border-bottom">
					📑 CV / curriculum vitæ
					</h3> */}
					<Preamble />
					<Skills />
					<Experiences />
					<Education />
					<Languages />
					<Various />
					{/* <Examples /> */}
					{/* <Pantheon /> */}
					{/* <Div1 />
					<Div2 />
					<Div3 /> */}
				</div>

				<div className="col-md-4">
					<Sidebar />
				</div>
			</div>
		</main>
}

export default MainContainer;
