function ScrollToTopButton({label}) {

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<button type="button" className="btn btn-link" onClick={scrollToTop}>
			{label} ⬆️
		</button>
	)
}

export default ScrollToTopButton;
