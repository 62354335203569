import { useTranslation } from "react-i18next";

function Skills() {
	const { t } = useTranslation();

	return <div className="cv-section container mb-4 p-2" id="skills">
				<h2 className="part-title mb-2 pb-2 border-bottom">{t('home_skills.title')} 🛠️</h2>

				{/* <p>Mon parcours m'a permis d'acquerir différentes compétences..</p> */}

				<table className="table">
					<tbody>
						<tr>
							<th>{t('home_skills.table_technos')}</th>
							<td><abbr title="❤️">Symfony</abbr>, PHP, Javascript, JQuery, ReactJS, NextJS, Python, SQL, HTML/CSS, BootstrapCSS, TailwindCSS</td>
						</tr>
						<tr>
							<th>{t('home_skills.table_tools')}</th>
							<td>Docker, Git, Gitlab ci, Postman, Insomnia, UML, Merise, Trello</td>
						</tr>
						<tr>
							<th>{t('home_skills.table_db')}</th>
							<td>MySQL, PostgreSQL, SQLite, Oracle, MongoDB</td>
						</tr>
						<tr>
							<th>{t('home_skills.table_testing')}</th>
							<td>PHPUnit, phpstan, php-cs-fixer, jest</td>
						</tr>
					</tbody>
				</table>
			</div>;
}

export default Skills;
