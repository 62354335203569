import { useTranslation } from "react-i18next";

function Various() {
	const { t } = useTranslation();

	return <div className="cv-section container mb-4 p-2" id="various">
				<h2 className="part-title mb-2 pb-2 border-bottom">{t('home_various.title')} 🏀</h2>

				<p>{t('home_various.list')}</p>
			</div>;
}

export default Various;
