import '../../styles/Sidebar.css';
import profilePic from './../../assets/images/profil_light.jpg';
import { useTranslation } from 'react-i18next';

function Sidebar() {
	const { t } = useTranslation();

    const age = calculateAge('1989-07-22');

	return <div className="position-sticky" style={{top: '2rem'}}>
			{/* <div className="p-4 mb-3 bg-body-tertiary rounded">
				<h4 className="fst-italic">About</h4>
				<p className="mb-0">Customize this section to tell your visitors a little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
			</div> */}

			<div className="sidebar-section p-2">
				<h4 className="fst-italic pb-2 border-bottom">
					{t('sidebar.info.title')}
				</h4>
				{/* <a className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none" href="#">
					<svg className="bd-placeholder-img" width="100%" height="96" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
					<div className="col-lg-8">
					<h6 className="mb-0">Example blog post title</h6>
					<small className="text-body-secondary">January 15, 2023</small>
					</div>
				</a> */}
				<div className="row mb-2">
					<div className="col-sm-6 col-md-12 col-lg-6 col-xl-6">
						<img src={profilePic} className="img-fluid mt-2" alt="profil pic" />
					</div>
					<div className="col-sm-6 col-md-12 col-lg-6 col-xl-6">
						<h4 className="fst-italic mt-2">David GAUTHIER</h4>
						<p className="mb-0">
							📆 {age} {t('sidebar.info.age')}
							<br />{t('sidebar.info.nationality')} 🇫🇷
							<br />📍 {t('sidebar.info.place')}
							{/* {# <br>🚗 permis B
							<br>🍼 sans enfant #} */}
						</p>
					</div>
				</div>

				<p className="fst-italic mb-0">
					{/* Particulièrement attaché au framework <abbr title="❤️">Symfony</abbr> et son écosystème mettant en avant les bonnes pratiques de développement, et intéressé par le concept DevOps via notamment Docker. */}
					{t('sidebar.info.phrase')}
				</p>
			</div>

			<div className="sidebar-section p-2">
				<h4 className="fst-italic pb-2 border-bottom">
					{t('sidebar.anchors.title')}
				</h4>
				<ol className="list-unstyled mb-3" id="anchors-list">
					<li><a className="p-1" href="#preamble">{t('sidebar.anchors.preamble')} 📖</a></li>
					<li><a className="p-1" href="#skills">{t('sidebar.anchors.skills')} 🛠️</a></li>
					<li><a className="p-1" href="#experiences">{t('sidebar.anchors.experiences')} 💼</a></li>
					<li><a className="p-1" href="#education">{t('sidebar.anchors.education')} 🎓</a></li>
					<li><a className="p-1" href="#languages">{t('sidebar.anchors.languages')} 💬</a></li>
					<li><a className="p-1" href="#various">{t('sidebar.anchors.various')} 🏀</a></li>
					{/* <li><a href="#pantheon">Pantheon</a></li> */}
				</ol>
			</div>

			<div className="sidebar-section p-2">
				<h4 className="fst-italic pb-2 border-bottom">
					{t('sidebar.links.title')}
				</h4>
				<ol className="list-unstyled mb-0">
					<li><a href="https://fr.linkedin.com/in/david-gauthier" target="_blank" rel="noreferrer">LinkedIn</a></li>
					<li><a href="https://github.com/davidgauthier" target="_blank" rel="noreferrer">GitHub</a></li>
					<li><a href="https://github.com/biron75" target="_blank" rel="noreferrer">GitHub</a></li>
					<li><a href="https://gitlab.com/biron75" target="_blank" rel="noreferrer">GitLab</a></li>
					<li><a href="https://openclassrooms.com/fr/members/xfzhrf2j70w5" target="_blank" rel="noreferrer">OpenClassrooms</a></li>
					<li><a style={{color: 'rgba(0,0,0,0.0)'}} href="https://biron75.com/contact/" target="_blank" rel="noreferrer">biron75</a></li>
				</ol>
			</div>
		</div>
}

const calculateAge = (bd) => {
    const currentDate = new Date();
    const birthDate = new Date(bd);
    let calculatedAge = currentDate.getFullYear() - birthDate.getFullYear();
    if (
        currentDate.getMonth() < birthDate.getMonth()
        || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
        calculatedAge--;
    }
    return calculatedAge;
};

export default Sidebar;
