import { useTranslation } from "react-i18next";

function Languages() {
	const { t } = useTranslation();

	return <div className="cv-section container mb-4 p-2" id="languages">
				<h2 className="part-title mb-2 pb-2 border-bottom">{t('home_languages.title')} 💬</h2>

				{/* <p>Bla bla..</p> */}

				<ul>
					<li>{t('home_languages.fr_lvl')}</li>
					<li>{t('home_languages.en_lvl')}</li>
					<li>{t('home_languages.es_lvl')}</li>
				</ul>
			</div>;
}

export default Languages;
