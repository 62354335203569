import { useEffect } from 'react';
import '../styles/About.css';
import Footer from './_common/Footer';
import Header from './_common/Header';
import MainContainer from './Home/MainContainer';
import ThemeButton from './_common/ThemeButton';
import handleContentLoaded from './_common/_bootstrap-color-mode'

function About() {
	useEffect(() => {
		handleContentLoaded();
	}, []);

	return <>
		<ThemeButton />
		<Header />
		{/* <MainContainer /> */}
		<div className='container'>About..</div>
		<Footer />
	</>
}

export default About;
