import { useTranslation } from "react-i18next";

function Education() {
	const { t } = useTranslation();

	return <div className="cv-section container mb-4 p-2" id="education">
				<h2 className="part-title mb-2 pb-2 border-bottom">{t('home_education.title')} 🎓</h2>

				{/* <p>Bla bla..</p> */}

				<h4>{t('home_education.others_table.title')} 👨‍💼</h4>
				<table className="table table-sm lh-sm">
					<tbody>
						<tr>
							<td>2023 (2j)</td>
							<td>Formation développement sécurisé (pentester)</td>
							<td><em>Ageas - La Défense</em></td>
						</tr>
						<tr>
							<td>2023 (3j)</td>
							<td>Formation bases Docker</td>
							<td><em>Ageas - La Défense</em></td>
						</tr>
						<tr>
							<td>2022 (5j)</td>
							<td>Formation développement C# .Net</td>
							<td><em>Ageas - La Défense</em></td>
						</tr>
						<tr>
							<td>2021 (3j)</td>
							<td>Formation Git/Gitlab</td>
							<td><em>Ageas - La Défense</em></td>
						</tr>
						<tr>
							<td>2017 (3&nbsp;mois)</td>
							<td>POE - Formation développement PHP - Symfony - Drupal</td>
							<td><em>DTA Ingénierie - Montpellier</em></td>
						</tr>
					</tbody>
				</table>

				<h4>{t('home_education.studies_table.title')} 👨‍🎓</h4>
				<table className="table">
					<tbody>
						<tr>
							<td>2014&nbsp;-&nbsp;2015</td>
							<td>🎓 <strong>Architecte Technique en Informatique et Réseaux (RNCP 1)</strong></td>
							<td><em>CFA INSTA - Paris&nbsp;5</em></td>
						</tr>
						<tr>
							<td>2011&nbsp;-&nbsp;2014</td>
							<td>Analyste Informaticien option Génie Logiciel</td>
							<td><em>CFA INSTA - Paris&nbsp;5</em></td>
						</tr>
						<tr>
							<td>2009&nbsp;-&nbsp;2011</td>
							<td>🎓 <strong>Brevet de Technicien Supérieur Informatique de Gestion</strong>, option Développeur d’Applications</td>
							<td><em>CFA INSTA - Paris&nbsp;5</em></td>
						</tr>
						<tr>
							<td>2008&nbsp;-&nbsp;2009</td>
							<td>1ère année Licence MIME (Mathématiques, Informatique, Mécanique, Electronique)</td>
							<td><em>Université Pierre et Marie CURIE - Paris&nbsp;5</em></td>
						</tr>
						<tr>
							<td>2007&nbsp;-&nbsp;2008</td>
							<td>🎓 <strong>BAC S</strong>, option Science de l’Ingénieur</td>
							<td><em>Lycée Arago - Paris&nbsp;12</em></td>
						</tr>
					</tbody>
				</table>
			</div>;
}

export default Education;
