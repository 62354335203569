import '../styles/Home.css';
import { useEffect } from 'react';
import Footer from './_common/Footer';
import Header from './_common/Header';
import MainContainer from './Home/MainContainer';
import ThemeButton from './_common/ThemeButton';
import handleContentLoaded from './_common/_bootstrap-color-mode';

function Home() {
	useEffect(() => {
		handleContentLoaded();
	}, []);

	return <>
		<ThemeButton />
		<Header />
		<MainContainer />
		<Footer />
	</>
}

export default Home;
