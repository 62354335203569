import { useTranslation } from "react-i18next";

function Experiences() {
	const { t } = useTranslation();

	return <div className="cv-section container mb-4 p-2" id="experiences">
				<h2 className="part-title mb-2 pb-2 border-bottom">{t('home_experiences.title')} 💼</h2>

				<h4 className="mt-4 mb-0">{t('home_experiences.exp6.title')}</h4>
				<p className="part-meta">
					{t('home_experiences.exp6.company')} <span className="float-end">{t('home_experiences.exp6.dates')}</span>
				</p>
				<p>
				{t('home_experiences.exp6.text')}
					<br />• {t('home_experiences.exp6.text2')}
					<br />• {t('home_experiences.exp6.text3')}
					<br />• {t('home_experiences.exp6.text4')}
					<br />• Docker, PHP (5.6/7), Symfony (2.8/4/5), ColdFusion (9/2021), Oracle, GitLab
				</p>

				<h4 className="mt-4 mb-0">{t('home_experiences.exp5.title')}</h4>
				<p className="part-meta">
					{t('home_experiences.exp5.company')} <span className="float-end">{t('home_experiences.exp5.dates')}</span>
				</p>
				<p>
					{t('home_experiences.exp5.text')}
					<br />• {t('home_experiences.exp5.text2')}
					<br />• {t('home_experiences.exp5.text3')}
					<br />• {t('home_experiences.exp5.text4')}
					<br />• {t('home_experiences.exp5.text5')}
				</p>

				<h4 className="mt-4 mb-0">{t('home_experiences.exp4.title')}</h4>
				<p className="part-meta">
					{t('home_experiences.exp4.company')} <span className="float-end">{t('home_experiences.exp4.dates')}</span>
				</p>
				<p>
					{t('home_experiences.exp4.text')}
					<br />• {t('home_experiences.exp4.text2')}
					<br />• {t('home_experiences.exp4.text3')}
					<br />• {t('home_experiences.exp4.text4')}
					<br />• {t('home_experiences.exp4.text5')}
				</p>

				<h4 className="mt-4 mb-0">{t('home_experiences.exp3.title')}</h4>
				<p className="part-meta">
					{t('home_experiences.exp3.company')} <span className="float-end">{t('home_experiences.exp3.dates')}</span>
				</p>
				<p>
					{t('home_experiences.exp3.text')}
					<br />• {t('home_experiences.exp3.text2')}
					<br />• {t('home_experiences.exp3.text3')}
				</p>

				<h4 className="mt-4 mb-0">{t('home_experiences.exp2.title')}</h4>
				<p className="part-meta">
					{t('home_experiences.exp2.company')} <span className="float-end">{t('home_experiences.exp2.dates')}</span>
				</p>
				<p>
					{t('home_experiences.exp2.text')}
					<br />• BusinessObjects XI 3.1 (Web Intelligence Rich Client, InfoView, Central Management Console)
					<br />• ETL Talend
				</p>

				<h4 className="mt-4 mb-0">{t('home_experiences.exp1.title')}</h4>
				<p className="part-meta">
					{t('home_experiences.exp1.company')} <span className="float-end">{t('home_experiences.exp1.dates')}</span>
				</p>
				<p>
					{t('home_experiences.exp1.text')}
					<br />• JAVA, J2E, Eclipse, Subversion, Tomcat, JSP, JasperReports
				</p>

			</div>;
}

export default Experiences;
